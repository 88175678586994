<template>
  <b-card>
    <FormView
      :data="$store.getters['paymentMethod/detailData']"
      :fields="$store.getters['paymentMethod/tableFields']"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('paymentMethod/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
